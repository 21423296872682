import request from '@/utils/request'

// 系统管理 - 菜单管理 - 获取所有菜单列表通过cate_id 区分
export function getMenuManageInternetHospitalList (params) {
  return request({
    url: '/admin/base/menu/index',
    method: 'GET',
    params
  })
}



// 系统管理 - 父级(下拉框数据, 树形结构)
export function getBaseMenuDropDownList (params) {
  return request({
    url: '/admin/base/menu/dropDownList',
    method: 'GET',
    params
  })
}

// 系统管理 - 创建/追加 菜单
export function postBaseMenuStore (data) {
  return request({
    url: '/admin/base/menu/store',
    method: 'POST',
    data
  })
}

// 系统管理 - 删除菜单
export function delBaseMenuDelete (params) {
  return request({
    url: '/admin/base/menu/delete',
    method: 'GET',
    params
  })
}

// 系统管理 - 修改菜单
export function putBaseMenuUpdate (data) {
  return request({
    url: '/admin/base/menu/update',
    method: 'PUT',
    data
  })
}

// 系统管理 启用/禁用 switch按钮
export function putBaseMenuExamine (data) {
  return request({
    url: '/admin/base/menu/examine',
    method: 'PUT',
    data
  })
}

// 系统管理 获取菜单分类列表
export function getBaseCateMenuList (params) {
  return request({
    url: '/admin/base/cateMenu/index',
    method: 'GET',
    params
  })
}

// 系统管理 创建菜单分类列表
export function postBaseCateMenuStore (data) {
  return request({
    url: '/admin/base/cateMenu/store',
    method: 'POST',
    data
  })
}

// 系统管理 删除菜单分类列表数据
export function delBaseCateMenuDelete (params) {
  return request({
    url: '/admin/base/cateMenu/delete',
    method: 'GET',
    params
  })
}

// 系统管理 修改菜单分类列表数据
export function putBaseCateMenuUpdate (data) {
  return request({
    url: '/admin/base/cateMenu/update',
    method: 'PUT',
    data
  })
}

// 系统管理 禁用或启用分类
export function putBaseCateMenuExamine (data) {
  return request({
    url: '/admin/base/cateMenu/examine',
    method: 'PUT',
    data
  })
}

// --------------------------------------------

// 系统管理 公告管理 获取公告消息列表
export function getNoticeList(params) {
  return request({
    url: '/admin/notice',
    method: 'GET',
    params
  })
}

// 系统管理 公告管理 增加通告

export function postNotice(data) {
  return request({
    url: `/admin/notice`,
    method: 'POST',
    data
  })
}

// 系统管理 公告管理 发布公告消息
export function postNoticePublish(id) {
  return request({
    url: `/admin/notice/publish/${id}`,
    method: 'POST',
  })
}

// 系统管理 公告管理 删除公告
export function delNotice(id) {
  return request({
    url: `/admin/notice/${id}`,
    method: 'DELETE',
  })
}

// 系统管理 公告管理 修改公告
export function putNotice(data) {
  return request({
    url: `/admin/notice/${data.id}`,
    method: 'PUT',
    data
  })
}


// 系统管理 获取消息列表
export function getMessageList(params) {
  return request({
    url: '/admin/message',
    method: 'GET',
    params
  })
}


// 系统管理 未读消息统计数,铃铛显示
export function getMessageCount(params) {
  return request({
    url: '/admin/message/count',
    method: 'GET',
    params
  })
}

// 系统管理 消息(铃铛) 消息详情
export function getMessageInfo(id) {
  return request({
    url: `/admin/message/info/${id}`,
    method: 'GET',
  })
}

// 系统管理 消息(铃铛) 未读消息标记已读
export function postMessageRead(id) {
  return request({
    url: `/admin/message/read/${id}`,
    method: 'POST',
  })
}

// 系统管理 消息(铃铛) 删除消息
export function delMessageDelMessage(id) {
  return request({
    url: `/admin/message/delMessage/${id}`,
    method: 'DELETE',
  })
}

// 系统管理 消息(铃铛) 删除所有已读消息
export function delMessageDelAllRead() {
  return request({
    url: '/admin/message/delAllRead',
    method: 'DELETE',
  })
}

// 系统管理 消息(铃铛) 所有未读消息标记已读
export function postMessageDelAllRead() {
  return request({
    url: '/admin/message/readAll',
    method: 'POST',
  })
}
//机构管理模块
//机构配置列表
export function getAgentSetIndex(params) {
  return request({
    url: '/admin/agent-set/index',
    method: 'GET',
    params
  })
}
//添加机构配置
export function postAgentSetAdd(data) {
  return request({
    url: '/admin/agent-set/add',
    method: 'POST',
    data
  })
}
//机构配置详情
export function getAgentSetDetail(id) {
  return request({
    url: `/admin/agent-set/detail/${id}`,
    method: 'GET',
  })
}
//修改机构配置
export function putAgentSetEdit(data) {
  return request({
    url: `/admin/agent-set/edit/${data.id}`,
    method: 'put',
    data
  })
}
//根据机构获取医生
export function getAgentSetDoctorSelect(params) {
  return request({
    url: `/admin/agent-set/doctor-select`,
    method: 'get',
    params
  })
}
//获取审方药师
export function getAgentSetPharmacistSelect(params) {
    return request({
        url: `/admin/agent-set/getPharmacyList`,
        method: 'get',
        params
    })
}
//删除
export function delAgentSetDelete(id) {
  return request({
    url: `/admin/agent-set/del/${id}`,
    method: 'delete',
  })
}
//机构科室列表
export function getAgentSetAgentDeparts(params) {
  return request({
    url: `/admin/agent-set/getAgentDeparts`,
    method: 'get',
    params
  })
}
//机构配置列表
export function getAgentSetNormalDeparts(params) {
  return request({
    url: `/admin/agent-set/getNormalDeparts`,
    method: 'get',
    params
  })
}
//设置机构配置
export function postAgentSetSetAgentDepart(data) {
  return request({
    url: `/admin/agent-set/setAgentDepart`,
    method: 'post',
    data
  })
}
//设置排序是否展示
export function postAgentSetSetHomeAgentDepart(data) {
  return request({
    url: `/admin/agent-set/setHomeAgentDepart`,
    method: 'post',
    data
  })
}
//机构配置病症
export function postSetAgentSymptom(data) {
  return request({
    url: `/admin/agent-set/setAgentSymptom`,
    method: 'post',
    data
  })
}
export function getSetAgentSymptom(params) {
  return request({
    url: `/admin/agent-set/getAgentSymptom`,
    method: 'get',
    params
  })
}
//获取版本信息
export function getAppVersionList(params) {
    return request({
        url: `/admin/app-version/list`,
        method: 'get',
        params
    })
}
//获取版本详情
export function getAppVersionDetail(id) {
    return request({
        url: `/admin/app-version/${id}`,
        method: 'get',
    })
}
//添加版本
export function postAppVersionAdd(data) {
    return request({
        url: `/admin/app-version/add`,
        method: 'post',
        data
    })
}
//修改版本
export function putAppVersionEdit(data) {
    return request({
        url: `/admin/app-version/edit/${data.id}`,
        method: 'post',
        data
    })
}
//删除版本
export function delAppVersionDelete(id) {
    return request({
        url: `admin/app-version/${id}`,
        method: 'delete',
    })
}