import {
	operates,
	getPermissions,
	getAgents
} from '@/api/auth/auth.js'
import {
	pharmacist,
} from '@/api/auth'

import { getAdminCommonMerchants } from "@/api/finance/index.js";
const state = {
  operates:[],
  permissions:[],
  agents:[],
  phars:[]
}

const mutations = {
	SET_OPERATES:(state,operates) =>{
		state.operates = operates
	},
	SET_PERMISSIONS:(state,permissions) =>{
		state.permissions = permissions
	},
	SET_AGENTS:(state,agents) =>{
		state.agents = agents
	},
	SET_PHARS:(state,phars) =>{
		state.phars = phars
	}
}


const actions = {
	getAgents({
		commit
	}){
		let agents = window.sessionStorage.getItem('AGENTS');
		if(agents){

			commit('SET_AGENTS',JSON.parse(agents))
		}else{
			getAdminCommonMerchants().then(response => {
				if (response.code == 200) { 
					console.log('99999vuex84238534895873495834',response)
					commit('SET_AGENTS', response.data)
					sessionStorage.setItem('AGENTS', JSON.stringify(response.data))
					
				} 
			})
		}
	},
	getPahrs({commit}){
		let phars = window.sessionStorage.getItem('PHARS');
		if(phars){

			commit('SET_PHARS',JSON.parse(phars))
		}else{
			pharmacist().then(response => {
				if (response.code == 200) { 
					commit('SET_PHARS', response.data)
					sessionStorage.setItem('PHARS', JSON.stringify(response.data))
					
				} 
			})
		}
	},
	// user login
	// eslint-disable-next-line no-empty-pattern
	getOperates({
		commit
	}) {
		let operate = window.sessionStorage.getItem('OPERATES');
		if(operate){
			console.log(1111111111,JSON.parse(operate))
			commit('SET_OPERATES',JSON.parse(operate))
		}else{
			operates().then(response => {
				console.log('99999',response)
				if (response.code == 200) { 
					commit('SET_OPERATES', response.data)
					sessionStorage.setItem('OPERATES', JSON.stringify(response.data))
					
				} 
			})
		}
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
