import request from '@/utils/request'

// 系统管理 - 角色管理 - 角色列表
export function getRoleList (params) {
  return request({
    url: '/admin/base/role/index',
    method: 'GET',
    params
  })
}

// 系统管理 - 角色管理 - 下拉角色列表
export function getRoleDropDownList (params) {
  return request({
    url: '/admin/base/role/dropDownList',
    method: 'GET',
    params
  })
}

// 系统管理 - 角色管理 - 权限树
export function getRoleTreeAuth (params) {
  return request({
    url: '/admin/base/role/treeAuth',
    method: 'GET',
    params
  })
}

// 系统管理 - 角色管理 - 新增角色
export function postRoleStore (data) {
  return request({
    url: '/admin/base/role/store',
    method: 'POST',
    data
  })
}

// 系统管理 - 角色管理 - 获取角色详情
export function getRoleShow (params) {
  return request({
    url: '/admin/base/role/show',
    method: 'GET',
    params
  })
}

// 系统管理 - 角色管理 - 修改角色
export function putRoleUpdate (data) {
  return request({
    url: '/admin/base/role/update',
    method: 'PUT',
    data
  })
}

// 系统管理 - 角色管理 - 启用和禁用
export function putRoleExamine (data) {
  return request({
    url: '/admin/base/role/examine',
    method: 'PUT',
    data
  })
}

// 系统管理 - 角色管理 - 删除角色
export function delRoleDelete (params) {
  return request({
    url: '/admin/base/role/delete',
    method: 'GET',
    params
  })
}

//---------------------

// 系统管理 - 员工管理 - 员工列表
export function getMemberIndex (params) {
  return request({
    url: '/admin/base/member/index',
    method: 'GET',
    params
  })
}

// 系统管理 - 员工管理 - 创建(新增)账户

export function postMemberStore (data) {
  return request({
    url: '/admin/base/member/store',
    method: 'POST',
    data
  })
}

// 系统管理 - 员工管理 - 修改密码
export function putMemberUPassword (params) {
  return request({
    url: '/admin/base/member/uPassword',
    method: 'GET',
    params
  })
}

// 系统管理 - 员工管理 - 删除员工
export function delMemberDelete (params) {
  return request({
    url: '/admin/base/member/delete',
    method: 'GET',
    params
  })
}

// 系统管理 - 员工管理 - 禁用启用
export function putMemberExamine (data) {
  return request({
    url: '/admin/base/member/examine',
    method: 'PUT',
    data
  })
}

// 系统管理 - 员工管理 - 员工详情
export function getMemberShow (params) {
  return request({
    url: '/admin/base/member/show',
    method: 'GET',
    params
  })
}

// 系统管理 - 员工管理 - 修改员工信息
export function putMemberUpdate (data) {
  return request({
    url: '/admin/base/member/update',
    method: 'PUT',
    data
  })
}

// 系统管理 - 员工管理 - 获取供应商列表
export function getMemberPharmacy (params) {
  return request({
    url: '/admin/base/member/pharmacy',
    method: 'GET',
    params
  })
}

// 系统管理 - 员工管理 - 获取渠道列表
export function getMemberAgent (params) {
  return request({
    url: '/admin/base/member/agent',
    method: 'GET',
    params
  })
}

// 系统管理 - 员工管理 - 获取药师列表
export function getMemberDruggist (params) {
  return request({
    url: '/admin/base/member/druggist',
    method: 'GET',
    params
  })
}

//----------------------

// 系统管理 - 权限分配 - 权限列表
export function getBaseAuthList (params) {
  return request({
    url: '/admin/base/auth/index',
    method: 'GET',
    params
  })
}

// 系统管理 - 权限父级(下拉框数据, 树形结构)
export function getBaseAuthDropDownList (params) {
  return request({
    url: '/admin/base/auth/dropDownList',
    method: 'GET',
    params
  })
}

// 系统管理 - 权限分配 - 新增/追加权限
export function postBaseAuthStore (data) {
  return request({
    url: '/admin/base/auth/store',
    method: 'POST',
    data
  })
}


// 系统管理 - 删除权限
export function delBaseAuthDelete (params) {
  return request({
    url: '/admin/base/auth/delete',
    method: 'GET',
    params
  })
}

// 系统管理 - 修改菜单
export function putBaseAuthUpdate (data) {
  return request({
    url: '/admin/base/auth/update',
    method: 'PUT',
    data
  })
}

// 系统管理 启用/禁用 switch按钮
export function putBaseAuthExamine (data) {
  return request({
    url: '/admin/base/auth/examine',
    method: 'PUT',
    data
  })
}

// 系统管理 权限隐藏/隐藏 switch按钮
export function putBaseAuthDisplay (data) {
  return request({
    url: '/admin/base/auth/display',
    method: 'PUT',
    data
  })
}
