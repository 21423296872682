import {
	operates
} from '@/api/finance'

import {
	agentList
} from '@/api/finance'

const state = {
  operates:[],
  permissions:[],
  agents:[]
}

const mutations = {
	SET_OPERATES:(state,operates) =>{
		state.operates = operates
	},
	SET_AGENTS:(state,agents) =>{
		state.agents = agents
	}
}


const actions = {
	// 获取机构列表
	getAgents({
		commit
	}){
		console.log('99999拿到echart6756')
		let agents = window.sessionStorage.getItem('AGENTS');
		if(agents){

			commit('SET_AGENTS',JSON.parse(agents))
		}else{
			agentList().then(response => {
				if (response.code == 200) { 
					commit('SET_AGENTS', response.data)
					sessionStorage.setItem('AGENTS', JSON.stringify(response.data))
					
				} 
			})
		}
	},
	// 增加机构
	addAgents({commit},agent){
		console.log('99999拿到echart6756')
		let agents = window.sessionStorage.getItem('AGENTS');
		agents = agents ? actions : []
		agents = agents.push({id:agent.id,merchant_name:agent.merchant_name})
		sessionStorage.setItem('AGENTS', JSON.stringify(agents))
		commit('SET_AGENTS', agents)
	},
	// 获取权限操作选项
	getOperates({
		commit
	}) {
		console.log('99999拿到echart6756')
		let operate = window.sessionStorage.getItem('OPERATES');
		if(operate){
			commit('SET_OPERATES',JSON.parse(operate))
		}else{
			operates().then(response => {
				console.log('99999拿到echart6756',response)
				if (response.code == 200) { 
					commit('SET_OPERATES', response.data)
					sessionStorage.setItem('OPERATES', JSON.stringify(response.data))
					
				} 
			})
		}
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
