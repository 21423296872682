import request from '@/utils/request'

// 待审核代理列表
export function getProxyExamineList(params) {
  return request({
    url: 'admin/proxy/examineList',
    method: 'GET',
    params
  })
}

// 代理审核
export function postExamineProxy(data, id) {
  return request({
    url: `admin/proxy/examine/${id}`,
    method: 'POST',
    data
  })
}

// 修改代理状态
export function postStatusProxy(id,data) {
  return request({
    url: `admin/proxy/status/${id}`,
    method: 'POST',
    data
  })
}

// 代理列表
export function getProxyList(params) {
  return request({
    url: 'admin/proxy/index',
    method: 'GET',
    params
  })
}

//  地址列表
export function getAddress() {
  return request({
    url: `admin/proxy/getAddress`,
    method: 'GET'
  })
}

// 新增 代理
export function addProxy(data) {
  return request({
    url: 'admin/proxy/addProxy',
    method: 'post',
    data
  })
}

export function setProxyRate(id,data) {
  return request({
    url: 'admin/proxy/setRate/'+ id,
    method: 'post',
    data
  })
}


