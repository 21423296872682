class WebSocketPlugin {
    constructor(url) {
      this.url = url;
      this.listeners = {
        open: [],
        close: [],
        error: [],
        message: []
      };
      this.connect();
    }

    connect() {
      this.socket = new WebSocket(this.url)
      this.socket.onopen = event => {
        this.triggerListeners('open', event);
      };
      this.socket.onclose = event => {
        this.triggerListeners('close', event);
        setTimeout(() => {
          this.connect(); // 重新连接
        }, 3000); // 3秒后尝试重新连接
      };
      this.socket.onerror = error => {
        this.triggerListeners('error', error);
        setTimeout(() => {
          this.connect(); // 重新连接
        }, 3000); // 30秒后尝试重新连接
      };
      this.socket.onmessage = message => {
        this.triggerListeners('message', message.data);
      };
    }

    send(message) {
      if (this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(message);
      }
    }

    disconnect() {
      this.socket.close();
    }

    addOpenListener(listener) {
      this.listeners.open.push(listener);
    }

    addCloseListener(listener) {
      this.listeners.close.push(listener);
    }

    addErrorListener(listener) {
      this.listeners.error.push(listener);
    }

    addMessageListener(listener) {
      this.listeners.message.push(listener);
    }

    triggerListeners(type, ...args) {
      this.listeners[type].forEach(listener => listener(...args));
    }
  }

  export default WebSocketPlugin;
