// websocket.js

import Vue from 'vue';
import WebSocketPlugin from '@/utils/websocket';
import {bindSocket} from '@/api/socket'
const state = {
  socket: null,
  connected: false,
  client_id: '',
  messageList:[],
};

const mutations = {
  SET_SOCKET(state, socket) {
    state.socket = socket;
  },
  SET_CONNECTED(state, connected) {
    state.connected = connected;
  },
  SET_CLIENTID(state, client_id) {
    state.client_id = client_id;
  },
  SET_MESSAGE(state, message) {
    let __VALUE__ = JSON.parse(JSON.stringify(state.messageList))
			// if (!__VALUE__[obj.key]) {
			// 	__VALUE__[obj.key] = []
			// }
			__VALUE__.push(message)
			state.messageList = __VALUE__
  },



};

const actions = {
  connect({ commit, state, dispatch }, url) {
    if (state.socket && state.connected) {
      return Promise.resolve();
    }

    const socket = new WebSocketPlugin(url);

    socket.addOpenListener(() => {
      commit('SET_SOCKET', socket);
      commit('SET_CONNECTED', true);
    });

    socket.addCloseListener(() => {
      commit('SET_SOCKET', null);
      commit('SET_CONNECTED', false);
    });

    socket.addErrorListener(error => {
      console.error(error);
    });

    socket.addMessageListener(data => {
      dispatch('onMessage', data);
    });

   Vue.prototype.$socket = socket;

    return Promise.resolve();
  },

  disconnect({ commit }) {
    const socket = Vue.prototype.$socket;

    if (socket) {
      socket.disconnect();
      commit('SET_SOCKET', null);
      commit('SET_CONNECTED', false);
      delete Vue.prototype.$socket;
    }

    return Promise.resolve();
  },

  onMessage({ getters,rootState,commit  }, data) {
    const message = JSON.parse(data);
    console.log("===message===",message)
    let type = message.type
    switch(type){
        case 'init':
            commit('SET_CLIENTID',message.client_id)
            console.log(11111111111,rootState)
            if(rootState.user.token&&rootState.user.token!=undefined){
                bindSocket({client_id:message.client_id}).then(res=>{

                })
            }
            break;
          case 'message':
          case 'take_message':
            commit('SET_MESSAGE',message)
            break;
          case 'close_window':
            if(navigator.userAgent.indexOf("Firefox") != -1 || navigator.userAgent.indexOf("Chrome") != -1){
                window.location.href = "about:blank";
                window.close();
            }else{
                window.opener = null;
                window.open("", "_self");
                window.close();
            }


    }
    // if (message.type === 'ping') {
    //   getters.send({ type: 'pong' });
    // }
  }
};

const getters = {
  send: state => message => {
    const socket = state.socket;

    if (socket && state.connected) {
      socket.send(JSON.stringify(message));
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
