import {
	login,
	getInfo,
	logout
} from '@/api/user'
import {
	Message,
	TimePicker
} from "element-ui";
import {
	setToken,
	getToken,
	removeToken
} from '@/utils/auth'
import {
	resetRouter,
	routesMap,
	asyncRoutes,
	constantRoutes,
	asyncRouterMap
} from '@/router'
import request from '@/utils/request';
import {
	getMessageCount
} from '@/api/systemManage'
import da from 'element-ui/src/locale/lang/da';
import { reject } from 'lodash';


export function filterCustomRoutes(asyncRouterMap, channels) {
	let res = []
	channels.forEach(channel => {
		const tmp = []
		asyncRouterMap.forEach(route => {
			if (route.type === channel) {
				tmp.push(...route.router)
			}
		})
		res = [...res, ...tmp]
	})
	return res
}

const state = {
	token: getToken(),
	name: '',
	avatar: '',
	introduction: '',
	adminId: '',
	roles: [],
	userType: '',
	pharmacyIntro: {},
	noticeCount: {},
	routeList: [],
	isActiveMenuBar: [],
	is_self:'',
	is_admin:'',
	pres_switch:undefined,
	level:0,
	operates: [],
	table_options: {}
}

const mutations = {
	SET_IS_SELF: (state, self) => {
		state.is_self=self
	},
	SET_IS_ADMIN: (state, self) => {
		state.is_admin=self
	},
	SET_TOKEN: (state, token) => {
		state.token = token
		setToken(token)
		console.log("``````````token````````", token)
	},
	SET_INTRODUCTION: (state, introduction) => {
		state.introduction = introduction
	},
	SET_NAME: (state, name) => {
		state.name = name
	},
	SET_AVATAR: (state, avatar) => {
		state.avatar = avatar
	},
	SET_ROLES: (state, roles) => {
		state.roles = roles
	},
	SET_USER_TYPE: (state, type) => {
		state.userType = type
	},
	SET_AGENT_PID: (state, pid) => {
		state.agentPid = pid
	},
	SET_PROXY_LEVEL:(state,level) => {
		state.level = level
	},
	SET_PHARMACY_INTRO: (state, params) => {
		state.pharmacyIntro = params
	},
	SET_USER_ID: (state, admin_id) => {
		state.adminId = admin_id
	},
	SET_NOTICE_COUNT: (state, notice) => {
		state.noticeCount.noticeCount = notice.noticeCount
		state.noticeCount.systemCount = notice.systemCount
		state.noticeCount.count = notice.count
	},
	SET_ROUTES_LIST: (state, routeList) => {
		state.routeList = routeList
	},
	SET_IS_ACTIVE_MENU_BAR: (state, isActiveMenuBar) => {
		state.isActiveMenuBar = isActiveMenuBar
	},
	SET_PRES_SWITCH:(state,pres_switch)=>{
		state.pres_switch=pres_switch
	},
	SET_OPERATES: (state, operates) => {
		state.operates = operates
	},
	SET_TABLE_OPTIONS: (state, table_options) => {
		state.table_options = table_options
	}
}

export function filterAdminRoutes(asyncRoutes) {
	const res = []
	asyncRoutes.forEach(route => {
		if (route.admin_hidden === undefined || !route.admin_hidden){
			const tmp = {
				...route
			}
			if (tmp.children) {
				console.log("======8888888888888==",tmp.name,tmp.admin_hidden)
				tmp.children = tmp.children.filter(function(item) {
					return item.admin_hidden == undefined || !item.admin_hidden
				})
			}
			res.push(tmp)
		}
			

		
	})
console.log("99999999999999999",res)
	return res
}

export function filterAsyncRoutes(serviceMap) {
	//服务端返回的路由与前端路由对比映射
	const res = []
	console.log("==serviceMap==",serviceMap)
	serviceMap.forEach(item => {
		console.log('routesMap', routesMap, item.path)
		if (routesMap[item.path]) {
			const tmp = {
				...routesMap[item.path],
				children: item.children ? item.children : []
			}
			if (tmp.children) {
				console.log(3333333, tmp.children)
				tmp.children = filterAsyncRoutes(tmp.children)
			}
			res.push(tmp)
		}
		console.log(res)
		console.log('????')
	})
	return res
}

const actions = {
	getOperates({commit,state},path){
		return new Promise((resolve, reject) => {
			let new_operates = []
			let operates = state.operates
			operates.forEach(operate => {
				if(operate[path]) {
					new_operates.push(...operate[path].split(','))
				}
			})
			resolve(new_operates)
		}).catch(err=>{
			reject(err)
		})
	
	},
	// user login
	// eslint-disable-next-line no-empty-pattern
	login({
		commit
	}, userInfo) {
		// const {loginName, password} = userInfo
		return new Promise((resolve, reject) => {
			login(userInfo).then(response => {
				let data = response
				
				if (data.code == 401) { //密码错误就不再请求token
					resolve(data.data)
					Message({
						message: data.message,
						type: "error",
						duration: 5 * 1000,
					});
					console.log("账号密码错")
				} else {
					commit('SET_TOKEN', data.data.token)
					resolve(data.data)
				}


			}).catch(error => {
				reject(error)
			})
		})
	},

	// get user info
	getInfo({
		commit
	} ) {
		return new Promise((resolve, reject) => {
			getInfo().then(response => {
				
				if (response.code != 200) {
					let msg  = response.msg || '该用户没有配置权限角色'
					reject(msg)
					return
				}
				let roles = []
				let data = response.data
				
				let operators = []

				data.permission.forEach(path => {
					if(path.children) {
						path.children.forEach(item => {
						let originOperators = {}
						var name = `/${path.path}/${item.path}`
							originOperators[name] = `${item.operates}`
							operators.push(originOperators)
						})
					}
				})
				commit('SET_TABLE_OPTIONS', data.options.table_options)
				commit('SET_OPERATES', operators)
				commit('SET_IS_ADMIN',data.is_admin)
				commit('SET_USER_ID', data.admin_id)
				commit('SET_AVATAR',
					'https://pic.hngegejk.cn/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20201016161303.png'
					)
				commit('SET_NAME', data.name)
				commit('SET_USER_TYPE', data.type)
				commit('SET_PRES_SWITCH',data.pres_switch)
				roles.push('agent')
				
				if (data.is_admin == 1) {
					// data.permissions = filterAdminRoutes(asyncRoutes)
					// console.log(asyncRouterMap)
					// commit('SET_IS_ACTIVE_MENU_BAR', ['互联网医院', '慢病管理', '三方药事', '系统管理', '商户管理'])
					// 后台的权限列表, 路由守卫
					const backPermissionRoute = filterAdminRoutes(asyncRoutes)
					data.permissions = backPermissionRoute

					// 前台的权限列表, 渲染菜单
					// const frontPermissionRoute = filterCustomRoutes(asyncRouterMap, payload)
					// const frontFilterAdminRoutes = filterAdminRoutes(frontPermissionRoute)
					commit('SET_ROUTES_LIST', [...constantRoutes, ...backPermissionRoute])
				} else {
					// 后台的权限列表, 路由守卫
					
					const backPermissionRoute = data.permission = filterAsyncRoutes(data.permission)
					data.permissions = backPermissionRoute
					
					// 前台的权限列表, 渲染菜单, 通过后台重新计算前台路由
					// const frontPermissionRoute = filterCustomRoutes(asyncRouterMap, payload)
					commit('SET_ROUTES_LIST', [...constantRoutes, ...backPermissionRoute])
					// console.log('%%%%%')
					// console.log(backPermissionRoute)
					// console.log(frontPermissionRoute)
					// console.log('%%%%')
				}
				commit('SET_ROLES', roles)
				console.log("======store.user.data==========",data)
				data.roles = roles
				resolve(data)
				// } else {
				// 	reject('该用户没有配置权限角色')
				// }
			}).catch(error => {
				console.log(error);
				reject('该用户没有配置权限角色')
			})

		})
	},

	// user logout
	logout({
		commit,
		state,
		dispatch
	}) {
		return new Promise((resolve, reject) => {
			logout(state.token).then((data) => {
				commit('SET_TOKEN', '')
				commit('SET_ROLES', [])
				removeToken()
				resetRouter()
				sessionStorage.clear()
				// reset visited views and cached views
				// to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
				dispatch('tagsView/delAllViews', null, {
					root: true
				})
				resolve(data)
			}).catch(error => {
				reject(error)
			})
		})
	},

	// remove token
	resetToken({
		commit
	}) {
		return new Promise(resolve => {
			commit('SET_TOKEN', '')
			commit('SET_ROLES', [])
			removeToken()
			resolve()
		})
	},

	// 获取用户未读消息统计数
	getNoticeCount({
		commit
	}) {
		return new Promise((resolve, reject) => {
			getMessageCount().then(response => {
				if (response.code === 200) {
					console.log('notice~~~~~~~~~~~~~~~~~~~~~~~~~~')
					commit('SET_NOTICE_COUNT', response.data)
					resolve()
				} else {
					reject('网络错误')
				}
			}).catch(error => {
				console.log(error);
			})
		})
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
