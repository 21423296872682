import {
	orderStatus,
	getDicData,
	getDicDataNew,
	bankOptions
} from '@/api/dictionary'
import {
	humpToLine
} from '@/utils/index'
import {
	form_drug
} from '@/dict/index'
import {
	getAddress
}from "@/api/audit/proxyAudit"
const state = {
	orderStatus: [], // 订单状态
	drugSort: {}, // 药品分类
	departDic: [], // 科室字典 /admin/setting/getDepartSelect
	articleCate: [], // 文章分类下拉 /admin/infoManage/getArticleCateSelect
	drugMethod: [], //用药方法 /admin/drugManage/getDrugMethodList
	questionCate: [], //问题分类 /admin/helpManage/getQuestionCateSelect
	replyGroup: [], //快捷回复分类 /admin/setting/getReplyGroupSelect
	departSymptom: [], //    科室疾病二级联动  /admin/setting/getDepartSymptomSelect
	presOrderStatus: [], //处方订单状态下拉   /admin/order/getPresOrderStatusSelect
	diaOrderStatus: [], //诊疗订单状态下拉  /admin/order/getDiaOrderStatusSelect
	doctorStatus: [{
		value: 1,
		label: '待审核'
	}, {
		value: 2,
		label: '审核中'
	}, {
		value: 3,
		label: '审核通过'
	}, {
		value: 4,
		label: '审核拒绝'
	}],
	doctorStatus2: [{
		value: 1,
		label: '待审核'
	}, {
		value: 2,
		label: '审核中'
	}],
	filingStatus: [{
		value: -2,
		label: '待上传'
	}, {
		value: -1,
		label: '挂起'
	}, {
		value: 0,
		label: '机构待审核'
	}, {
		value: 1,
		label: '监管待审核'
	}, {
		value: 2,
		label: '审核通过'
	},{
		value: 3,
		label: '打回'
	}],
	nation: [{
			"label": "汉族",
			"value": "01"
		},
		{
			"label": "蒙古族",
			"value": "02"
		},
		{
			"label": "回族",
			"value": "03"
		},
		{
			"label": "藏族",
			"value": "04"
		},
		{
			"label": "维吾尔族",
			"value": "05"
		},
		{
			"label": "苗族",
			"value": "06"
		},
		{
			"label": "彝族",
			"value": "07"
		},
		{
			"label": "壮族",
			"value": "08"
		},
		{
			"label": "布依族",
			"value": "09"
		},
		{
			"label": "朝鲜族",
			"value": "10"
		},
		{
			"label": "满族",
			"value": "11"
		},
		{
			"label": "侗族",
			"value": "12"
		},
		{
			"label": "瑶族",
			"value": "13"
		},
		{
			"label": "白族",
			"value": "14"
		},
		{
			"label": "土家族",
			"value": "15"
		},
		{
			"label": "哈尼族",
			"value": "16"
		},
		{
			"label": "哈萨克族",
			"value": "17"
		},
		{
			"label": "傣族",
			"value": "18"
		},
		{
			"label": "黎族",
			"value": "19"
		},
		{
			"label": "傈僳族",
			"value": "20"
		},
		{
			"label": "佤族",
			"value": "21"
		},
		{
			"label": "畲族",
			"value": "22"
		},
		{
			"label": "高山族",
			"value": "23"
		},
		{
			"label": "拉祜族",
			"value": "24"
		},
		{
			"label": "水族",
			"value": "25"
		},
		{
			"label": "东乡族",
			"value": "26"
		},
		{
			"label": "纳西族",
			"value": "27"
		},
		{
			"label": "景颇族",
			"value": "28"
		},
		{
			"label": "柯尔克孜族",
			"value": "29"
		},
		{
			"label": "土族",
			"value": "30"
		},
		{
			"label": "达斡尔族",
			"value": "31"
		},
		{
			"label": "仫佬族",
			"value": "32"
		},
		{
			"label": "羌族",
			"value": "33"
		},
		{
			"label": "布朗族",
			"value": "34"
		},
		{
			"label": "撒拉族",
			"value": "35"
		},
		{
			"label": "毛难族",
			"value": "36"
		},
		{
			"label": "仡佬族",
			"value": "37"
		},
		{
			"label": "锡伯族",
			"value": "38"
		},
		{
			"label": "阿昌族",
			"value": "39"
		},
		{
			"label": "普米族",
			"value": "40"
		},
		{
			"label": "塔吉克族",
			"value": "41"
		},
		{
			"label": "怒族",
			"value": "42"
		},
		{
			"label": "乌孜别克族",
			"value": "43"
		},
		{
			"label": "俄罗斯族",
			"value": "44"
		},
		{
			"label": "鄂温克族",
			"value": "45"
		},
		{
			"label": "崩龙族",
			"value": "46"
		},
		{
			"label": "保安族",
			"value": "47"
		},
		{
			"label": "裕固族",
			"value": "48"
		},
		{
			"label": "京族",
			"value": "49"
		},
		{
			"label": "塔塔尔族",
			"value": "50"
		},
		{
			"label": "独龙族",
			"value": "51"
		},
		{
			"label": "鄂伦春族",
			"value": "52"
		},
		{
			"label": "赫哲族",
			"value": "53"
		},
		{
			"label": "门巴族",
			"value": "54"
		},
		{
			"label": "珞巴族",
			"value": "55"
		},
		{
			"label": "基诺族\t",
			"value": "56"
		}
	], // 民族
	uploadStatus: [{
		"label": "未上传",
		"value": "1"
	},
	// {
	// 	"label": "待上传",
	// 	"value": "2"
	// },
	{
		"label": "已上传",
		"value": "3"
	}], //药师监管
	genderDic: [], //性别
	doctorTitleDic: [], //医生职称
	pracLevelDic: [], //医师执业级别
	pracScopeDic: [], //医师执业范围
	pracTypeDic: [], //医师执业类别
	doctorDic: [], // 医师详情页面用到的字典
	organizationsDic: [], // 执业机构   /admin/organizations
	drugUnits: [], // 药品单位
	drugForms: [], // 药品剂型
	drugs: {
		drug_dosage: [],
		drug_unit: []
	},
	proxy_address:[],
	bank_options:[]
}

const mutations = {
	SET_BANK_OPTIONS: (state, data) => {
		state.bank_options = data
	},
	SET_ORDER_STATUS: (state, data) => {
		state.orderStatus = data
	},
	SET_DIC_STATE: (state, data) => {
		state[data.stateKey] = data.res
	},
	SET_PROXY_ADDRESS: (state, data) => {
		state.proxy_address = data
	},
}

const actions = {
	getDicData({
		commit
	}, dicCodes) {
		for (let i = 0; i < dicCodes.length; i++) {
			let item = dicCodes[i]
			if (item.stateName == 'drugForms') {
				commit('SET_DIC_STATE', {
					stateKey: item.stateName,
					res: form_drug
				})
				return
			}
			//session key   mutaions key  api url
			let uKey = humpToLine(item.stateName).toLocaleUpperCase()
			let sessionKey = '_DIC_' + uKey + '_'
			if (!state[item.stateName]) {
				state[item.stateName] = []
			}
			let dicData = window.sessionStorage.getItem(sessionKey);
			if (dicData) {
				commit('SET_DIC_STATE', {
					stateKey: item.stateName,
					res: JSON.parse(dicData)
				})
			} else {
				getDicDataNew(item.api).then(response => {
					let data = response
					if (data.code == 200) {
						commit('SET_DIC_STATE', {
							stateKey: item.stateName,
							res: data.data
						});
						window.sessionStorage.setItem(sessionKey, JSON.stringify(data.data))
					}
				}).catch(error => {
					console.log(error)
				})
			}

		}

	},

	getOrderStatus({
		commit
	}) {
		//session key   mutaions key  api url
		let dicData = sessionStorage.getItem('DIC_ORDER_STATUS');
		if (dicData) {
			commit('SET_ORDER_STATUS', JSON.parse(dicData))
		} else {
			orderStatus().then(response => {
				let data = response
				if (data.code == 200) {
					commit('SET_ORDER_STATUS', data.data)
					sessionStorage.setItem('DIC_ORDER_STATUS', JSON.stringify(data.data))
				}
			}).catch(error => {
				console.log(error)
			})
		}
	},

	getProxyAddress({commit}){
		let address = sessionStorage.getItem('DIC_PROXY_ADDRESS');
		if(address){
			commit('SET_PROXY_ADDRESS',JSON.parse(address))
		}else{
			getAddress().then(res=>{
				if(200 == res.code){
					this.adddress = res.data
					commit('SET_PROXY_ADDRESS',res.data)
					sessionStorage.setItem('DIC_PROXY_ADDRESS', JSON.stringify(res.data))
				}
			}).catch(err=>{
				console.log(err)
			})
		}
	},


	getBankOptions({commit}){
		let banks = sessionStorage.getItem('DIC_BANK_OPTIONS');
		if(banks){
			commit('SET_BANK_OPTIONS',JSON.parse(banks))
		}else{
			bankOptions().then(res=>{
				if(200 == res.code){
					commit('SET_BANK_OPTIONS',res.data)
					sessionStorage.setItem('DIC_BANK_OPTIONS', JSON.stringify(res.data))
				}
			}).catch(err=>{
				console.log(err)
			})
		}
	}


}

export default {
	namespaced: true, //namespaced: true 的方式使其成为带命名空间的模块。保证在变量名一样的时候，添加一个父级名拼接。
	state,
	mutations,
	actions
}
