import Vue from 'vue'

import './plugins/element.js'
import '@/styles/index.scss' // global css
import 'font-awesome/scss/font-awesome.scss' // https://fontawesome.dashgame.com/ 图标库
import App from './App.vue'
import router from './router'
import store from './store'
import { Message } from 'element-ui'
import './icons' // icon
import './font/cdm_font/iconfont.js' // 彩色图标 icon
import './permission'
import config from "@/utils/config"; // 权限控制


import $ from 'jquery'
Vue.prototype.$ = $
Vue.config.productionTip = false
Vue.prototype.$echarts = window.echarts

// 时间处理
import moment  from 'moment'
Vue.prototype.$moment = moment

const { mockXHR } = require('../mock')
mockXHR()

Vue.config.productionTip = false

Vue.filter('filterCDNUrl', function (url) {
  if (!url){
    return ''
  } else {
    if (url.indexOf('http') > -1){
      return  url
    }else{
      return config.qiniuUrl + url
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
