<template>
	<div id="app">
		<router-view/>
	</div>
</template>

<script>
	export default {
		name: "app",
		data() {
			return {};
		},
		computed: {
			key() {
				return this.$route.path + Math.random();
			},
		},
	};
</script>
<style>
	body {
		height: 100%;
		margin: 0;
		padding: 0;
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		text-rendering: optimizeLegibility;
		font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
			Microsoft YaHei, Arial, sans-serif;
	}

	.popover-width {
		width: 87% !important;
	}
</style>
