import Mock from 'mockjs'
// const users = {
//   'admin-token': {
//     roles: ['admin'],
//     introduction: 'I am a super administrator',
//     avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
//     name: 'Super Admin'
//   },
//   'editor-token': {
//     roles: ['editor'],
//     introduction: 'I am an editor',
//     avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
//     name: 'Normal Editor'
//   }
// }

export default [
  // user login

  {
    url: '/logout',
    type: 'post',
    response: () => {

      return {
        code: 200,
        data: 'ok'
      }
    }
  },
  // {
  //   url: '/list',
  //   type: 'post',
  //   response: () => {
  //
  //     return {
  //       code: 200,
  //       data: [{
  //         id: '1',
  //         info: '汪惠 18717771641',
  //         platform: '格格健康',
  //         doctor: '杨永信',
  //         customer : '郭靖',
  //         status: 0,
  //         createTime: '2020-1-1 12:00:00'
  //       },{
  //         id: '2',
  //         info: '汪惠 18717771641',
  //         platform: '格格健康',
  //         doctor: '杨永信',
  //         customer : '郭靖',
  //         status: 0,
  //         createTime: '2020-1-1 12:00:00'
  //       },{
  //         id: '3',
  //         info: '汪惠 18717771641',
  //         platform: '格格健康',
  //         doctor: '杨永信',
  //         customer : '郭靖',
  //         status: 0,
  //         createTime: '2020-1-1 12:00:00'
  //       }]
  //     }
  //   }
  // },
  {
    url: '/captcha',
    type: 'get',
    response: () => {

      return {
        code: 200,
        data: '1232'
      }
    }
  },
  {
    url: '/dashboard',
    type: 'get',
    response: () => {
      let s = []
      let d = []
      for (let i=0;i<20;i++){
        s.push(Mock.Random.float( 10, 300 ))
        d.push(Mock.Random.datetime('yyyy-MM-dd'))
      }

      return {
        code: 200,
        data: {
          series:s,
          date:d
        }
      }
    }
  },
]
