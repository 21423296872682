<template>
	<div class="">
		<el-button type="text">文字按钮</el-button>
		<el-button type="text">文字按钮</el-button>
		<el-button type="text">文字按钮</el-button>
	</div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped lang="scss">

</style>
