import request from '@/utils/request'

export function authList(params) {
  return request({
    url: '/admin/auth',
    method: 'get',
    params
  })
}

export function addAuth(data) {
  return request({
    url: '/admin/auth',
    method: 'post',
    data
  })
}

export function updateAuth(id,data) {
  return request({
    url: `/admin/auth/${id}/auth`,
    method: 'put',
    data
  })
}

export function delAuth(id) {
  return request({
    url: `/admin/auth/${id}/auth`,
    method: 'delete',
  })
}

//顶级角色联动列表
export function getChainslAuth() {
  return request({
    url: `/admin/auth/roleChains`,
    method: 'get',
  })
}

//顶级权限列表
export function getTopAuth() {
  return request({
    url: `/admin/auth/getTopAuth`,
    method: 'get',
  })
}

//角色列表
export function roleList(params) {
  return request({
    url: `/admin/auth/role`,
    method: 'get',
    params
  })
}

//角色状态修改
export function roleStatus(id,data) {
  return request({
    url: `/admin/auth/role/${id}/status`,
    method: 'put',
    data
  })
}

//add角色
export function addRole(data) {
  return request({
    url: `/admin/auth/role`,
    method: 'post',
    data
  })
}



//update角色
export function updateRole(id,data) {
  return request({
    url: `/admin/auth/role/${id}`,
    method: 'put',
    data
  })
}

//
export function roleAuthList(params) {
  return request({
    url: `/admin/auth/role/roleAuthList`,
    method: 'get',
    params
  })
}

export function getRoles() {
  return request({
    url: `/admin/auth/role/roles`,
    method: 'get',
  })
}
//管理员账号列表
export function adminList(params) {
  return request({
    url: `/admin/auth/admin`,
    method: 'get',
    params
  })
}

//管理员账号列表
export function adminStatus(id,status) {
  return request({
    url: `/admin/auth/admin/${id}/status`,
    method: 'put',
    data:{status}
  })
}

export function addAdmin(data) {
  return request({
    url: `/admin/auth/admin`,
    method: 'post',
    data
  })
}

export function updateAdmin(id,data) {
  return request({
    url: `/admin/auth/admin/${id}/update`,
    method: 'put',
    data
  })
}

//药房
export function pharmacyRoom() {
  return request({
    url: `/admin/auth/admin/pharmacyRoom`,
    method: 'get',
  })
}

//药师
export function pharmacist() {
  return request({
    url: `/admin/auth/admin/pharmacist`,
    method: 'get',
  })
}

export function agentList() {
  return request({
    url: `/admin/merchantList`,
    method: 'get',
  })
}


//获取顶级商户列表
export function agents(type = 0) {
  return request({
    url: `/admin/topMerchants`,
    method: 'get',
    params:{type}
  })
}

export function updatePwd(id,data) {
    return request({
      url: `/admin/auth/admin/updatePwd/${id}`,
      method: 'put',
      data
    })
  }








