import request from '@/utils/request'


//顶级角色联动列表
export function bindSocket(data) {
  return request({
    url: `/admin/socket/bind`,
    method: 'post',
    data
  })
}
