import request from '@/utils/request'


// 结算管理 列表
export function getAdminBills(params) {
  return request({
    url: 'admin/bills',
    method: 'GET',
    params
  })
}

// 结账管理 收入明细
export function getAdminBillsIncomes (params) {
  return request({
    url : `admin/bills/incomes/${params.id}`,
    method: 'GET',
    params
  })
}

// 结账管理 审核提现
export function putAdminBillsExamine (data) {
  return request({
    url : `admin/bills/examine/${data.id}`,
    method: 'PUT',
    data
  })
}

// 结账管理 导出收入
export function postAdminBillsExportIncome (data) {
  return request({
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    responseType: 'blob',
    url : `admin/bills/exportIncome?${data}`,
    method: 'POST',
  })
}

// 机构端详情
export function getAdminReportAgentDetail (params) {
  return request({
    url : 'admin/report/agentDetail',
    method: 'GET',
    params
  })
}

// 代理对比报表
export function getProxyReportCompareList (params) {
  return request({
    url : 'admin/report/proxyCompare',
    method: 'GET',
    params
  })
}

// 机构对比报表
export function getAdminReportAgentList (params) {
  return request({
    url : 'admin/report/agentList',
    method: 'GET',
    params
  })
}

// 机构对比报表
export function getReportDetail (params) {
  return request({
    url : 'admin/report/proxyDetail',
    method: 'GET',
    params
  })
}

// 
export function getProxyList (params) {
  return request({
    url : 'admin/proxy/proxyList',
    method: 'GET',
    params
  })
}




// 医生详情报表
export function getAdminReportDoctorDetail (params) {
  return request({
    url : 'admin/report/doctorDetail',
    method: 'GET',
    params
  })
}

// 医生对比报表
export function getAdminReportDoctorCompare (params) {
  return request({
    url : 'admin/report/doctorCompare',
    method: 'GET',
    params
  })
}

// 商户选项列表
export function getAdminCommonMerchants (params) {
  return request({
    url : 'admin/common/merchants',
    method: 'GET',
    params
  })
}

// 医生选项列表
export function getAdminCommonDocList (params) {
  return request({
    url : 'admin/common/docList',
    method: 'GET',
    params
  })
}

// 监管数据对比报表
export function getSuperviseReportCompare (params) {
  return request({
    url : 'admin/report/superviseCompare',
    method: 'GET',
    params
  })
}

// 监管数据对比报表
export function getSuperviseDocCompare (params) {
  return request({
    url : 'admin/report/superviseDocCompare',
    method: 'GET',
    params
  })
}

// 监管数据对比报表
export function getSuperviseDetail (params) {
  return request({
    url : 'admin/report/superviseDetail',
    method: 'GET',
    params
  })
}

// 
export function getAgentDocList (params) {
  return request({
    url : 'admin/report/agentDocList',
    method: 'GET',
    params
  })
}

// 结账管理 审核提现
export function putSendIncome (doctorId,data) {
  return request({
    url : `admin/bills/sendIncome/${doctorId}`,
    method: 'PUT',
    data
  })
}


export function proxyInfo () {
  return request({
    url : `admin/proxy/accountInfo`,
    method: 'get',
  })
}



export function addBankCard (data) {
  return request({
    url : `admin/proxy/addBankCard`,
    method: 'post',
    data
  })
}

export function updateBankCard (id,data) {
  return request({
    url : `admin/proxy/updateBankCard/${id}`,
    method: 'post',
    data
  })
}

export function doctorReport (params) {
  return request({
    url : `admin/proxy/doctorReport`,
    method: 'get',
    params
  })
}

export function doctorReportMonth (params) {
  return request({
    url : `admin/proxy/docReportMonth`,
    method: 'get',
    params
  })
}

export function proxyBillsList (params) {
  return request({
    url : `admin/proxy/proxyBillsList`,
    method: 'get',
    params
  })
}


export function postProxyBills (data) {
  return request({
    url : `admin/proxy/proxyBills`,
    method: 'post',
    data
  })
}


export function getProxyIncomeList (params) {
  return request({
    url : `admin/proxy/incomeList`,
    method: 'get',
    params
  })
}

export function getproxyBillsList (params) {
  return request({
    url : `admin/proxyBills`,
    method: 'get',
    params
  })
}

export function proxyIncomeList (id,params) {
  return request({
    url : `admin/proxyIncomeList/${id}`,
    method: 'get',
    params
  })
}


export function examineProxyBill (id,data) {
  return request({
    url : `admin/examineBills/${id}`,
    method: 'post',
    data
  })
}

export function getDocReport (params) {
  return request({
    url : `admin/doctor/report`,
    method: 'get',
    params
  })
}
export function operates() {
  return request({
    url: `admin/operates`,
    method: 'get'
  })
}


























