import request from '@/utils/request'

export function getDicData(url) {
    if (!url){
        return false
    }else {
        return request({
            url: url,
            method: 'get'
        })
    }
}
//获取字典(新)
export function getDicDataNew(url) {
    if (!url){
        return false
    }else {
        return request({
            url: url,
            method: 'get'
        })
    }
}


export function orderStatus() {
    return request({
        url: '/getOrderStatus',
        method: 'get'
    })
}

//药品分类  /admin/drugManage/getDrugCateSelect

export function drugSort() {
    return request({
        url: '/admin/drugManage/getDrugCateSelect',
        method: 'get'
    })
}


export function bankOptions () {
    return request({
      url : `admin/bankOptions`,
      method: 'get',
    })
  }
