const form_drug = [
    {form_id:1,form_name:'口服常释剂型'},
    {form_id:2,form_name:'缓释控释剂型'},
    {form_id:3,form_name:'口服液体剂'},
    {form_id:4,form_name:'丸剂'},
    {form_id:5,form_name:'颗粒剂'},
    {form_id:6,form_name:'口服散剂'},
    {form_id:7,form_name:'外用散剂'},
    {form_id:8,form_name:'软膏剂'},
    {form_id:9,form_name:'贴剂'},
    {form_id:10,form_name:'外用液体剂'},
    {form_id:11,form_name:'硬膏剂'},
    {form_id:12,form_name:'凝胶剂'},
    {form_id:13,form_name:'涂剂'},
    {form_id:14,form_name:'拴剂'},
    {form_id:15,form_name:'滴眼剂'},
    {form_id:16,form_name:'滴耳剂'},
    {form_id:17,form_name:'滴鼻剂'},
    {form_id:18,form_name:'吸入剂'},
    {form_id:19,form_name:'注射剂'},
    {form_id:100,form_name:'其他'},
];

export {
    form_drug
}