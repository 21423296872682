import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
// import {baseAsyncRoutes} from './modules/base'
// import {internetHospitalBaseAsyncRoutes} from './modules/internetHospital'
// import {cdmAsyncRoutes} from './modules/cdm'
// import {supplyAsyncRoutes} from './modules/supply'


// // 当设置 true 的时候该路由不会在侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
// hidden: true // (默认 false)
//
// //当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
// redirect: 'noRedirect'
//
// // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
// // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
// // 若你想不管路由下面的 children 声明的个数都显示你的根路由
// // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
// alwaysShow: true
//
// name: 'router-name' // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
// meta: {
//     roles: ['admin', 'editor'] // 设置该路由进入的权限，支持多个权限叠加
//     title: 'title' // 设置该路由在侧边栏和面包屑中展示的名字
//     icon: 'svg-name' // 设置该路由的图标，支持 svg-class，也支持 el-icon-x element-ui 的 icon
//     noCache: true // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
//     breadcrumb: false //  如果设置为false，则不会在breadcrumb面包屑中显示(默认 true)
//     affix: true // 如果设置为true，它则会固定在tags-view中(默认 false)
//
//     // 当路由设置了该属性，则会高亮相对应的侧边栏。
//     // 这在某些场景非常有用，比如：一个文章的列表页路由为：/article/list
//     // 点击文章进入文章详情页，这时候路由为/article/1，但你想在侧边栏高亮文章列表的路由，就可以进行如下设置
//     activeMenu: '/article/list'
// }

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export const constantRoutes = [{
    path: '/login',
    component: () =>
        import('@/views/login/index'),
    hidden: true
},
    {
        path: '/auth-redirect',
        component: () =>
            import('@/views/login/auth-redirect'),
        hidden: true
    },
    // {
    //   name: 'dashboard',
    //   path: '/',
    //   component: Layout,
    //   redirect: '/dashboard',
    //   children: [{
    //     path: 'dashboard',
    //     name: 'dashboard',
    //     component: () =>
    //       import('@/views/dashboard/index'),
    //     meta: {
    //       title: '首页',
    //       icon: 'el-icon-s-home'
    //     }
    //   }]
    // },
]


export const asyncRoutes = [{
    name: 'dashboard',
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [{
        path: 'dashboard',
        name: 'dashboard',
        component: () =>
            import('@/views/dashboard/index'),
        meta: {
            title: '首页',
            icon: 'el-icon-s-home'
        }
    }]
},
    {
        name: 'auditManage',
        path: '/auditManage',
        component: Layout,
        redirect: 'noRedirect',
        meta: {
            title: '审核管理',
            roles: ['admin', 'auditor'],
            icon: "el-icon-question"
        },
        children: [{
            path: 'doctorAudit',
            name: 'doctorAudit',
            component: () =>
                import('@/views/auditManage/doctorAudit'),
            meta: {
                title: '医生审核',
                roles: ['admin'],
            }
        },
            {
                path: 'pharmacistAudit',
                name: 'pharmacistAudit',
                component: () =>
                    import('@/views/auditManage/pharmacistAudit'),
                meta: {
                    title: '药师审核',
                    roles: ['auditor'],
                }
            }, {
                path: 'recipeAudit',
                name: 'recipeAudit',
                component: () =>
                    import('@/views/auditManage/recipeAudit'),
                meta: {
                    title: '处方审核',
                    roles: ['auditor'],
                }
            },
            {
                path: 'drugAgentAudit',
                name: 'drugAgentAudit',
                component: () =>
                    import('@/views/auditManage/drugAgentAudit'),
                meta: {
                    title: '中药代煎审核',
                    roles: ['auditor', 'admin'],
                }
            },
            {
                path: 'organAudit',
                name: 'organAudit',
                component: () =>
                    import('@/views/auditManage/organAudit'),
                meta: {
                    title: '机构审核',
                    roles: ['auditor'],
                }
            },
            {
                path: 'proxyAudit',
                name: 'proxyAudit',
                component: () =>
                    import('@/views/auditManage/proxyAudit'),
                meta: {
                    title: '代理审核',
                    roles: ['auditor'],
                }
            },
            {
                path: 'commentAudit',
                name: 'commentAudit',
                component: () =>
                    import('@/views/auditManage/commentAudit'),
                meta: {
                    title: '评论审核',
                    roles: ['admin'],
                }
            }
        ]
    },
    {
        path: '/credentManage',
        component: Layout,
        redirect: 'noRedirect',
        name: 'credentManage',
        meta: {
            title: '资质管理',
            roles: ['admin'],
            icon: "el-icon-user"
        },
        children: [{
            path: 'doctorManage',
            name: 'doctorManage',
            component: () =>
                import('@/views/credentManage/doctorManage'),
            meta: {
                title: '医师管理',
            }
        },
            {
                path: 'addDoctor',
                name: 'addDoctor',
                component: () =>
                    import('@/views/credentManage/addDoctor'),
                meta: {
                    title: '医师管理',
                },
                hidden: true
            },
            {
                path: 'pharmacistManage',
                name: 'pharmacistManage',
                component: () =>
                    import('@/views/credentManage/pharmacistManage'),
                meta: {
                    title: '药师管理',
                }
            },
            {
                path: 'assistantManage',
                name: 'assistantManage',
                component: () =>
                    import('@/views/credentManage/assistantManage'),
                meta: {
                    title: '医助管理',
                }
            },

            {
                path: 'reviewContent',
                name: 'reviewContent',
                component: () =>
                    import('@/views/credentManage/reviewContent'),
                meta: {
                    title: '内容管理',
                }
            },
            {
                path: 'addPhar',
                name: 'addPhar',
                component: () =>
                    import('@/views/credentManage/addDoctor'),
                meta: {
                    title: '新增药师',
                },
                hidden: true
            },
            {
                path: 'organ',
                name: 'organ',
                component: () =>
                    import('@/views/credentManage/organ'),
                meta: {
                    title: '机构管理',
                }
            },
            {
                path: 'proxy',
                name: 'proxy',
                component: () =>
                    import('@/views/credentManage/proxy'),
                meta: {
                    title: '代理管理',
                }
            }
        ]
    },
    {
        name: 'drugsManage',
        path: '/drugsManage',
        component: Layout,
        redirect: 'noRedirect',
        meta: {
            title: '药品管理',
            roles: ['admin', 'pharmacy'],
            icon: 'el-icon-data-board'
        },
        children: [{
            path: 'drugsList',
            component: () =>
                import('@/views/drugsManage/drugsList'), // Parent router-view
            name: 'drugsList',
            meta: {
                title: '成药管理',
                roles: ['admin'],
            },
        },
            {
                path: 'TCMList',
                component: () =>
                    import('@/views/drugsManage/TCMList'), // Parent router-view
                name: 'TCMList',
                meta: {
                    title: '中药管理',
                    roles: ['admin'],
                },
            },
            {
                path: 'addDrug',
                component: () =>
                    import('@/views/drugsManage/addDrug'), // Parent router-view
                name: 'addDrug',
                meta: {
                    title: '新增药品',
                    roles: ['admin'],
                },
                hidden: true
            }
        ]
    },
    {
        path: '/hospitalBasic',
        component: Layout,
        redirect: '/',
        name: 'hospitalBasic',
        meta: {
            title: '医院基础',
            roles: ['admin'],
            icon: "el-icon-monitor"
        },
        children: [{
            path: 'dataDicts',
            component: () =>
                import('@/views//hospitalBasic/dataDicts'), // Parent router-view
            name: 'dataDicts',
            meta: {
                title: '数据字典'
            },
        }, {
            path: 'doctorLanguage',
            component: () =>
                import('@/views//hospitalBasic/doctorLanguage'), // Parent router-view
            name: 'doctorLanguage',
            meta: {
                title: '快捷回复'
            },
        },
            {
                path: 'disease',
                component: () =>
                    import('@/views/hospitalBasic/disease'), // Parent router-view
                name: 'disease',
                meta: {
                    title: '病症管理'
                },
            },
            {
                path: 'depart',
                component: () =>
                    import('@/views/hospitalBasic/depart'), // Parent router-view
                name: 'depart',
                meta: {
                    title: '科室管理'
                },
            },
            // {
            //   path: 'newDepart',
            //   component: () =>
            //     import('@/views/hospitalBasic/newDepart'), // Parent router-view
            //   name: 'newDepart',
            //   meta: {
            //     title: '新科室管理'
            //   },
            // },
            {
                path: 'hospitalManager',
                component: () =>
                    import('@/views/hospitalBasic/hospitalManager'), // Parent router-view
                name: 'hospitalManager',
                meta: {
                    title: '医院管理'
                },
            },
            {
                path: 'usageManager',
                component: () =>
                    import('@/views/hospitalBasic/usageManager'), // Parent router-view
                name: 'usageManager',
                meta: {
                    title: '用法管理'
                },
            }
        ],
        // children: [{
        // 		path: 'replyList',
        // 		component: () =>
        // 			import('@/views/basicManage/replyList'), // Parent router-view
        // 		name: 'replyList',
        // 		meta: {
        // 			title: '快捷回复管理'
        // 		},
        // 		hidden: true

        // 	},
        // 	{
        // 		path: 'medicationMethod',
        // 		component: () =>
        // 			import('@/views/basicManage/medicationMethod'), // Parent router-view
        // 		name: 'medicationMethod',
        // 		meta: {
        // 			title: '用药方法'
        // 		},
        // 	},
        // 	{
        // 		path: 'contraindication',
        // 		component: () =>
        // 			import('@/views/basicManage/contraindication'), // Parent router-view
        // 		name: 'contraindication',
        // 		meta: {
        // 			title: '用药禁忌'
        // 		},
        // 	},

        // 	{
        // 		path: 'departmentManage',
        // 		component: () =>
        // 			import('@/views/basicManage/departmentManage'), // Parent router-view
        // 		name: 'departmentManage',
        // 		meta: {
        // 			title: '科室管理'
        // 		},
        // 	},
        // 	{
        // 		path: 'symptomManage',
        // 		component: () =>
        // 			import('@/views/basicManage/symptomManage'), // Parent router-view
        // 		name: 'symptomManage',
        // 		meta: {
        // 			title: '擅长/病症管理'
        // 		},
        // 	}, {
        // 		path: 'diseaseDesc',
        // 		component: () =>
        // 			import('@/views/basicManage/diseaseDesc'), // Parent router-view
        // 		name: 'diseaseDesc',
        // 		meta: {
        // 			title: '病情描述'
        // 		},
        // 		hidden: true
        // 	}, {
        // 		path: 'drugDiagnosis',
        // 		component: () =>
        // 			import('@/views/basicManage/drugDiagnosis'), // Parent router-view
        // 		name: 'drugDiagnosis',
        // 		meta: {
        // 			title: '辩证&临床诊断'
        // 		},
        // 	}, {
        // 		path: 'hotSearch',
        // 		component: () =>
        // 			import('@/views/basicManage/hotSearch'), // Parent router-view
        // 		name: 'hotSearch',
        // 		meta: {
        // 			title: '热搜管理'
        // 		},
        // 	}, {
        // 		path: 'bannerManager',
        // 		component: () =>
        // 			import('@/views/basicManage/bannerManager'), // Parent router-view
        // 		name: 'bannerManager',
        // 		meta: {
        // 			title: '轮播图管理'
        // 		},
        // 	}, {
        // 		path: 'appraiseManager',
        // 		component: () =>
        // 			import('@/views/basicManage/appraiseManager'), // Parent router-view
        // 		name: 'appraiseManager',
        // 		meta: {
        // 			title: '评价管理'
        // 		},
        // 	}, {
        // 		path: 'hospitalManager',
        // 		component: () =>
        // 			import('@/views/basicManage/hospitalManager'), // Parent router-view
        // 		name: 'hospitalManager',
        // 		meta: {
        // 			title: '医院管理'
        // 		},
        // 	},

        // ]
    },
    {
        path: '/workManagement',
        name: 'workManagement',
        component: Layout,
        redirect: 'noRedirect',
        meta: {
            title: '排班管理',
            roles: ['admin'],
            icon: "el-icon-date"
        },
        children: [{
            path: 'shiftManagement',
            name: 'shiftManagement',
            component: () =>
                import('@/views/workManagement/shiftManagement'),
            meta: {
                title: '班次管理',
                roles: ['admin']
            }
        }, {
            path: 'formCalendar',
            name: 'formCalendar',
            component: () =>
                import('@/views/workManagement/formCalendar'),
            meta: {
                title: '班表日历',
                roles: ['admin']
            }
        },
            {
                path: 'makeAppointment',
                name: 'makeAppointment',
                component: () =>
                    import('@/views/workManagement/makeAppointment'),
                meta: {
                    title: '预约列表',
                    roles: ['admin']
                }
            },
        ]
    },
    {
        path: '/articleManage',
        name: 'articleManage',
        component: Layout,
        redirect: 'noRedirect',
        meta: {
            title: '文章管理',
            roles: ['admin'],
            icon: "el-icon-reading"
        },
        children: [
            {
                path: 'articleList',
                name: 'articleList',
                component: () =>
                    import('@/views/articleManage/articleList'), // Parent router-view
                meta: {
                    title: '文章列表',
                },
            },
            {
                path: 'articleTags',
                component: () =>
                    import('@/views/articleManage/articleTags'), // Parent router-view
                name: 'articleTags',
                meta: {
                    title: '文章标签'
                },
                hidden: true //是否显示在菜单
            },
            {
                path: 'articleDetail',
                component: () =>
                    import('@/views/articleManage/articleDetail'), // Parent router-view
                name: 'articleDetail',
                meta: {
                    title: '文章信息'
                },
                hidden: true //是否显示在菜单
            },
        ]
    },
    {
        path: '/informationSearch',
        name: 'informationSearch',
        component: Layout,
        redirect: 'noRedirect',
        meta: {
            title: '信息查询',
            roles: ['admin'],
            icon: "el-icon-search"
        },
        children: [{
            path: 'therapy',
            name: 'therapy',
            component: () =>
                import('@/views/informationSearch/therapy'),

            meta: {
                title: '问诊信息',
                roles: ['admin']
            }
        }, {
            path: 'pres',
            name: 'pres',
            component: () =>
                import('@/views/informationSearch/pres'),

            meta: {
                title: '处方管理',
                roles: ['admin']
            }
        }, {
            path: 'presDelivery',
            name: 'presDelivery',
            component: () =>
                import('@/views/informationSearch/presDelivery'),

            meta: {
                title: '处方流转',
                roles: ['admin']
            }
        }, {
            path: 'appraise',
            name: 'appraise',
            component: () =>
                import('@/views/informationSearch/appraise'),

            meta: {
                title: '评论管理',
                roles: ['admin']
            }
        }, {
            path: 'message',
            name: 'message',
            component: () =>
                import('@/views/informationSearch/message'),

            meta: {
                title: '短信查询',
                roles: ['admin']
            }
        },]
    },
    {
        path: '/supervise',
        name: 'supervise',
        component: Layout,
        redirect: 'noRedirect',
        meta: {
            title: '医院监管',
            roles: ['admin'],
            icon: "el-icon-box"
        },
        children: [{
            path: 'diagSupervise',
            name: 'diagSupervise',
            component: () =>
                import('@/views/supervise/diagSupervise'),

            meta: {
                title: '问诊监管',
                roles: ['admin']
            }
        }, {
            path: 'flowSupervise',
            name: 'flowSupervise',
            component: () =>
                import('@/views/supervise/flowSupervise'),

            meta: {
                title: '流转监管',
                roles: ['admin']
            }
        }, {
            path: 'doctorSupervise',
            name: 'doctorSupervise',
            component: () =>
                import('@/views/supervise/doctorSupervise'),

            meta: {
                title: '医师监管',
                roles: ['admin']
            }
        }, {
            path: 'pharSupervise',
            name: 'pharSupervise',
            component: () =>
                import('@/views/supervise/pharSupervise'),

            meta: {
                title: '药师监管',
                roles: ['admin']
            }
        }, {
            path: 'organSupervise',
            name: 'organSupervise',
            component: () =>
                import('@/views/supervise/organSupervise'),

            meta: {
                title: '机构监管',
                roles: ['admin']
            }
        },]
    },

    {
        path: '/chinaDrug',
        name: 'chinaDrug',
        component: Layout,
        redirect: 'noRedirect',
        meta: {
            title: '中药处方',
            roles: ['admin'],
            icon: "el-icon-s-operation"
        },
        children: [{
            path: 'photoDrug',
            name: 'photoDrug',
            component: () =>
                import('@/views/chinaDrug/photoDrug'),
            meta: {
                title: '拍方抓药',
                roles: ['admin']
            }
        }, {
            path: 'photoDrugAudit',
            name: 'photoDrugAudit',
            component: () =>
                import('@/views/chinaDrug/photoDrugAudit'),
            meta: {
                title: '处方审核',
                roles: ['admin']
            }
        }]
    },


    {
        path: '/patientManagement',
        name: 'patientManagement',
        component: Layout,
        redirect: 'noRedirect',
        meta: {
            title: '患者管理',
            roles: ['admin'],
            icon: "el-icon-s-custom"
        },
        children: [{
            path: 'patientManage',
            name: 'patientManage',
            component: () =>
                import('@/views/patientManagement/patientManagement'),
            meta: {
                title: '患者管理',
                roles: ['admin']
            }
        }]
    },

    {
        path: '/finance',
        name: 'finance',
        component: Layout,
        redirect: 'noRedirect',
        meta: {
            title: '财务管理',
            roles: ['admin'],
            icon: "el-icon-money"
        },
        children: [
            {
                path: 'cashOperation',
                name: 'cashOperation',
                component: () =>
                    import('@/views/finance/cashOperation'),
                meta: {
                    title: '提现明细',
                    roles: ['admin']
                }
            }, {
                path: 'docBill',
                name: 'docBill',
                component: () =>
                    import('@/views/finance/doctorManage'),
                meta: {
                    title: '返点补贴',
                    roles: ['admin']
                }
            }]
    },
    {
        name: 'report',
        path: '/report',
        component: Layout,
        redirect: 'noRedirect',
        meta: {
            title: '报表统计',
            roles: ['admin', 'pharmacy'],
            icon: 'el-icon-document-copy'
        },
        children: [{
            path: 'operateReport',
            name: 'operateReport',
            component: () =>
                import('@/views/report/operate'),
            meta: {
                title: '运营报表',
                roles: ['admin']
            }
        }, {
            path: 'organizationReport',
            name: 'organizationReport',
            component: () =>
                import('@/views/report/organizationReport'),
            meta: {
                title: '机构报表',
                roles: ['admin']
            }
        },
            {
                path: 'doctorsReport',
                name: 'doctorsReport',
                component: () =>
                    import('@/views/report/docReport'),
                meta: {
                    title: '医师报表',
                    roles: ['admin']
                }
            },
            {
                path: 'doctorReport',
                name: 'doctorReport',
                component: () =>
                    import('@/views/report/doctorReport'),
                meta: {
                    title: '医生报表',
                    roles: ['admin']
                }
            }, {
                path: 'superviseReport',
                name: 'superviseReport',
                component: () =>
                    import('@/views/report/superviseReport'),
                meta: {
                    title: '监管报表',
                    roles: ['admin']
                }
            }]
    },
    {
        name: 'proxyAccount',
        path: '/proxyAccount',
        component: Layout,
        redirect: 'noRedirect',
        meta: {
            title: '账户信息',
            roles: ['admin', 'pharmacy'],
            icon: 'el-icon-user-solid'
        },
        admin_hidden: true,
        children: [
            {
                path: 'accountInfo',
                component: () => import('@/views/proxyAccount/accountInfo'), // Parent router-view
                name: 'accountInfo',
                meta: {
                    title: '账户信息',
                    roles: ['admin'],
                },
            }
        ]
    },
    {
        name: 'proxyDoctor',
        path: '/proxyDoctor',
        component: Layout,
        redirect: 'noRedirect',
        meta: {
            title: '医师管理',
            roles: ['admin', 'pharmacy'],
            icon: 'el-icon-s-cooperation'
        },
        admin_hidden: true,
        children: [
            {
                path: 'doctorConfirm',
                component: () => import('@/views/proxyDoctor/doctorConfirm'), // Parent router-view
                name: 'doctorConfirm',
                meta: {
                    title: '签约确认',
                    roles: ['admin'],
                },
            },
            {
                path: 'proxyDoc',
                component: () => import('@/views/credentManage/doctorManage'), // Parent router-view
                name: 'proxyDoc',
                meta: {
                    title: '医师列表',
                    roles: ['admin'],
                },
            },
            {
                path: 'proxyAddDoc',
                component: () => import('@/views/credentManage/addDoctor'), // Parent router-view
                name: 'proxyAddDoc',
                meta: {
                    title: '新建医师',
                    roles: ['admin'],
                },
                hidden: true
            }
        ]
    },
    {
        name: 'proxyManage',
        path: '/proxyManage',
        component: Layout,
        redirect: 'noRedirect',
        meta: {
            title: '代理管理',
            roles: ['admin', 'pharmacy'],
            icon: 'el-icon-s-cooperation'
        },
        admin_hidden: true,
        children: [
            {
                path: 'proxyList',
                component: () => import('@/views/credentManage/proxy'), // Parent router-view
                name: 'proxyList',
                meta: {
                    title: '代理列表',
                    roles: ['admin'],
                },
            }
        ]
    },
    {
        name: 'proReport',
        path: '/proReport',
        component: Layout,
        redirect: 'noRedirect',
        meta: {
            title: '报表统计',
            roles: ['admin', 'pharmacy'],
            icon: 'el-icon-s-cooperation'
        },
        admin_hidden: true,
        children: [
            {
                path: 'proxyReport',
                component: () => import('@/views/proxyReport/proxyReport'), // Parent router-view
                name: 'proxyReport',
                meta: {
                    title: '代理报表',
                    roles: ['admin'],
                },
            },
            {
                path: 'docReport',
                component: () => import('@/views/proxyReport/doctorReport'), // Parent router-view
                name: 'docReport',
                meta: {
                    title: '医生报表',
                    roles: ['admin'],
                },
            }
        ]
    },

    {
        name: 'systemManage',
        path: '/systemManage',
        component: Layout,
        redirect: 'noRedirect',
        meta: {
            title: '系统管理',
            roles: ['admin', 'pharmacy'],
            icon: 'el-icon-s-tools'
        },
        children: [
            {
                path: 'systemConfig',
                component: () =>
                    import('@/views/systemManage/systemConfig'), // Parent router-view
                name: 'systemConfig',
                meta: {
                    title: '系统配置',
                    roles: ['admin'],
                },
            },
            {
                path: 'organizationList',
                component: () =>
                    import('@/views/systemManage/organizationList'), // Parent router-view
                name: 'organizationList',
                meta: {
                    title: '机构配置',
                    roles: ['admin'],
                },
            }, {
                path: 'versionsList',
                component: () =>
                    import('@/views/systemManage/versionsList'), // Parent router-view
                name: 'versionsList',
                meta: {
                    title: '版本管理',
                    roles: ['admin'],
                },
            }

        ]
    },
// {
// 	name: 'systemBasic',
// 	path: '/systemBasic',
// 	component: Layout,
// 	redirect: 'noRedirect',
// 	meta: {
// 		title: '系统基础',
// 		roles: ['admin', 'pharmacy'],
// 		icon: 'el-icon-s-tools'
// 	},
// 	children: [{
// 			path: 'orderLog',
// 			component: () =>
// 				import('@/views/systemBasic/orderLog'), // Parent router-view
// 			name: 'orderLog',
// 			meta: {
// 				title: '订单日志',
// 				roles: ['admin'],
// 			},
// 		},
// 		{
// 			path: 'exception',
// 			component: () =>
// 				import('@/views/systemBasic/exception'), // Parent router-view
// 			name: 'exception',
// 			meta: {
// 				title: '异常监控',
// 				roles: ['admin'],
// 			},
// 		},
// 		{
// 			path: 'noticeManagement',
// 			component: () =>
// 				import('@/views/systemBasic/noticeManagement'), // Parent router-view
// 			name: 'noticeManagement',
// 			meta: {
// 				title: '公告管理',
// 				roles: ['admin'],
// 			},
// 		},systemConfig
// 		{
// 			path: 'noticeManagementCreateOrEdit',
// 			component: () =>
// 				import('@/views/systemBasic/noticeManagementCreateOrEdit'), // Parent router-view
// 			name: 'noticeManagement',
// 			hidden: true,
// 			meta: {
// 				title: '公告管理配置',
// 				roles: ['admin'],
// 			},
// 		}
// 	]
// },

    {
        path: '/auth',
        name: 'auth',
        component: Layout,
        redirect: 'noRedirect',
        meta: {
            title: '权限管理',
            roles: ['admin'],
            icon: "el-icon-lock"
        },
        children: [{
            path: 'user',
            name: 'user',
            component: () =>
                import('@/views/accountAuth/account'),
            meta: {
                title: '账号管理',
                roles: ['admin']
            }
        }, {
            path: 'role',
            name: 'role',
            component: () =>
                import('@/views/accountAuth/role'),
            meta: {
                title: '角色管理',
                roles: ['admin'],
            }
        }, {
            path: 'permission',
            name: 'permission',
            component: () =>
                import('@/views/accountAuth/auth'),
            meta: {
                title: '权限管理'
            }
        }]
    },

// {
//   path: '/author',
//   name: 'author',
//   component: Layout,
//   redirect: 'noRedirect',
//   meta: {
//     title: '权限管理',
//     roles: ['admin'],
//     icon: "el-icon-lock"
//   },
//   children: [{
//     path: 'account',
//     name: 'account',
//     component: () =>
//       import('@/views/author/account'),
//     meta: {
//       title: '账号管理',
//       roles: ['admin']
//     }
//   }, {
//     path: 'role',
//     name: 'role',
//     component: () =>
//       import('@/views/author/role'),
//     meta: {
//       title: '角色管理',
//       roles: ['admin'],
//     }
//   }, {
//     path: 'auth',
//     name: 'auth',
//     component: () =>
//       import('@/views/author/auth'),
//     meta: {
//       title: '权限管理'
//     }
//   }]
// },
    {
        path: '/template',
        name: 'template',
        component: Layout,
        redirect: 'noRedirect',
        meta: {
            title: '测试',
            roles: ['admin'],
            icon: "el-icon-lock"
        },
        children: [{
            path: 'SingInputAndTable',
            name: 'SingInputAndTable',
            component: () =>
                import('@/views/template/SingInputAndTable'),
            meta: {
                title: '单行选择加表格',
                roles: ['admin']
            }
        },
            {
                path: 'MultipleInputAndOther',
                name: 'MultipleInputAndOther',
                component: () =>
                    import('@/views/template/MultipleInputAndOther'),
                meta: {
                    title: '多行选择其他样式',
                    roles: ['admin']
                }
            }, {
                path: 'DrawOne',
                name: 'DrawOne',
                component: () =>
                    import('@/views/template/DrawOne'),
                meta: {
                    title: '抽屉一',
                    roles: ['admin']
                }
            }, {
                path: 'DrawTwo',
                name: 'DrawTwo',
                component: () =>
                    import('@/views/template/DrawTwo'),
                meta: {
                    title: '抽屉二',
                    roles: ['admin']
                }
            }, {
                path: 'DialogOne',
                name: 'DialogOne',
                component: () =>
                    import('@/views/template/DialogOne'),
                meta: {
                    title: '弹出框',
                    roles: ['admin']
                }
            }, {
                path: 'ImportExportDown',
                name: 'ImportExportDown',
                component: () =>
                    import('@/views/template/ImportExportDown'),
                meta: {
                    title: '导入,导出,本地下载模板',
                    roles: ['admin']
                }
            }, {
                path: 'EchartBar',
                name: 'EchartBar',
                component: () =>
                    import('@/views/template/EchartBar'),
                meta: {
                    title: '柱状图',
                    roles: ['admin']
                }
            }, {
                path: 'EchartPie',
                name: 'EchartPie',
                component: () =>
                    import('@/views/template/EchartPie'),
                meta: {
                    title: '饼状图',
                    roles: ['admin']
                }
            }, {
                path: 'therapy1',
                name: 'therapy1',//名称不要重复
                component: () =>
                    import('@/views/template/therapy'),
                meta: {
                    title: '动态表头',
                    roles: ['admin']
                }
            }, {
                path: 'ProductIntroduce',
                name: 'ProductIntroduce',
                component: () =>
                    import('@/views/template/ProductIntroduce'),
                meta: {
                    title: '产品介绍',
                    roles: ['admin']
                }
            }, {
                path: 'TTTest',
                name: 'TTTest',
                component: () =>
                    import('@/views/template/TTTest'),
                meta: {
                    title: 'TTTest',
                    roles: ['admin']
                }
            },]
    },
]

// export const asyncRoutes = [
//   ...baseAsyncRoutes,
//   ...internetHospitalBaseAsyncRoutes,
//   ...cdmAsyncRoutes,
//   ...supplyAsyncRoutes,
// ]

const createRouter = () => new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({
        y: 0
    }),
    routes: constantRoutes
})

const router = createRouter()
// router.addRoutes(dynamicRouter)

export const routesMap = parseMap(asyncRoutes)

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}


export function parseMap(arr) {

    let routesMap = {}

    function flat(arr) {
        return arr.reduce((pre, cur) => {

            if (cur.name) {
                routesMap[cur.name] = cur
            }

            return pre.concat(Array.isArray(cur.children) ? flat(cur.children) : cur)
        }, [])

    }

    flat(arr)
    return routesMap
}


// export const asyncRouterMap = [
//   { type: 'baseAsyncRoutes', router: baseAsyncRoutes },
//   { type: 'internetHospitalBaseAsyncRoutes', router: internetHospitalBaseAsyncRoutes },
//   { type: 'cdmAsyncRoutes', router: cdmAsyncRoutes },
//   { type: 'supplyAsyncRoutes', router: supplyAsyncRoutes },
// ]

export default router
